import { getAuthority } from "@/libs/auth";
export default {
  path: "/company-manage/company-client/review",
  name: "review-company-client",
  meta: {
    auth: true,
    title: "审核客户公司"
  },
  component: function component() {
    return import("@/pages/dsp/company-manage/company-client/review");
  }
  // beforeEnter: async (to, from, next) => {
  //     let isPermission = await getAuthority(357);
  //     if (isPermission) {
  //         next();
  //     } else {
  //         next({ name: "403" });
  //     }
  // },
};