import { getAuthority, getInvoiceExamineOrApply } from "@/libs/auth";
export default {
  path: "/operate-bill/plan/edit",
  name: "operate-bill-plan-edit",
  meta: {
    auth: true,
    title: "编辑计划"
  },
  component: function component() {
    return import("@/pages/dsp/financial-manage/payment-plan/operate-bill/plan/edit");
  }
  // beforeEnter: async (to, from, next) => {
  // 	let isPermission;
  // 	if(to.query.id){
  // 		isPermission = await getInvoiceExamineOrApply(to.query.id);
  // 	}else{
  // 		isPermission = await getAuthority(463);
  // 	}
  // 	if (isPermission) {
  // 	    next();
  // 	} else {
  // 	    next({ name: "403" });
  // 	}
  // },
};