import "core-js/modules/es6.function.name";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "custom-form-table"
  }, [_vm._l(_vm.customFieldList, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "custom-form-td"
    }, [_c("div", {
      staticClass: "left"
    }, [_vm._v(_vm._s(item.name) + "：")]), _c("div", {
      staticClass: "right"
    }, [item.fieldTypeId == 1 ? _c("div", [item.value ? [item.fieldSubTypeId == 1 ? _c("Tooltip", {
      staticClass: "table-eli",
      attrs: {
        content: item.value,
        placement: "top",
        transfer: ""
      }
    }, [_c("div", {
      staticClass: "text-eli"
    }, [_vm._v(_vm._s(item.value))])]) : item.fieldSubTypeId == 2 ? _c("div", [_vm._v(_vm._s(item.value))]) : _vm._e()] : _c("span", [_vm._v("-")])], 2) : item.fieldTypeId == 2 ? _c("div", [_c("span", [_vm._v(_vm._s(item.optionNames || "-"))])]) : item.fieldTypeId == 3 ? _c("div", [_vm._v("\n\t\t\t\t" + _vm._s(item.value || "-") + "\n\t\t\t")]) : item.fieldTypeId == 4 ? _c("div", [item.value == null ? _c("span", [_vm._v("-")]) : [item.fieldSubTypeId == 7 ? _c("span", [_vm._v(_vm._s(item.value))]) : item.fieldSubTypeId == 8 ? _c("span", [_vm._v("\n\t\t\t\t\t\t" + _vm._s(_vm._f("formatNumbers")(item.value, item.decimalPlace)) + "\n\t\t\t\t\t")]) : item.fieldSubTypeId == 9 ? _c("span", [_vm._v("\n\t\t\t\t\t\t" + _vm._s(_vm._f("formatMoneys")(item.value, item.currencyUnit, item.decimalPlace)) + "\n\t\t\t\t\t")]) : _vm._e()]], 2) : _vm._e()])]);
  }), _vm.showLast ? _c("div", {
    staticClass: "custom-form-td"
  }, [_c("div", {
    staticClass: "left"
  }), _c("div", {
    staticClass: "right"
  })]) : _vm._e()], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };