export default {
  name: 'fk-loading',
  props: {
    fixed: Boolean,
    visible: Boolean,
    text: String
  },
  data: function data() {
    return {
      loadingText: '加载中...'
    };
  }
};