import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.array.find";
import _defineProperty from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { mapState } from "vuex";
export default {
  name: 'dsp-site-icon',
  props: {
    site: {
      type: String,
      default: null
    },
    placement: {
      type: String,
      default: 'top'
    },
    theme: {
      type: String,
      default: 'light'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {};
  },
  computed: _objectSpread(_objectSpread({}, mapState('admin/site', ['sites'])), {}, {
    style: function style() {
      var _this$sites$find,
        _this = this;
      var icon = (_this$sites$find = this.sites.find(function (v) {
        var _this$site;
        return v.site.toLowerCase() === ((_this$site = _this.site) === null || _this$site === void 0 ? void 0 : _this$site.toLowerCase());
      })) === null || _this$sites$find === void 0 ? void 0 : _this$sites$find.icon;
      return this.site ? {
        backgroundImage: "url(".concat(icon, ")")
      } : null;
    }
  })
};