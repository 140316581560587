export default {
  name: 'FkDrawerSelect',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '500'
    }
  },
  computed: {
    drawerShow: {
      get: function get() {
        return this.value;
      },
      set: function set(newVal) {
        this.$emit('input', newVal);
      }
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    confirm: function confirm() {
      this.$emit('confirm');
    },
    reset: function reset() {
      this.$emit('reset');
    }
  }
};