import iMenuSideItem from "./menu-item";
import iMenuSideTitle from "./menu-title";
export default {
  name: 'iMenuSideSubmenu',
  components: {
    iMenuSideItem: iMenuSideItem,
    iMenuSideTitle: iMenuSideTitle
  },
  props: {
    menu: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  }
};