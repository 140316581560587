export default {
  path: '/logs-manage',
  title: '日志管理',
  children: [{
    path: '/logs-manage/import-logs',
    title: '导入日志'
  }, {
    path: '/logs-manage/export-logs',
    title: '导出日志'
  }, {
    path: '/logs-manage/error-sap-logs',
    title: '错误报告'
  }]
};