var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("Dropdown", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.dropdownListLength > 0,
      expression: "dropdownListLength > 0"
    }],
    staticClass: "dropdown-wrap",
    attrs: {
      transfer: ""
    }
  }, [_c("a", {
    attrs: {
      href: "javascript:void(0)"
    }
  }, [_c("span", [_vm._v("更多")]), _c("Icon", {
    attrs: {
      type: "ios-arrow-down"
    }
  })], 1), _c("DropdownMenu", {
    ref: "dropdown-list",
    attrs: {
      slot: "list"
    },
    slot: "list"
  }, [_vm._t("default")], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };