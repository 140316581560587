import { getAuth } from '@/libs/auth';
export default {
  path: "/system-config/high-seas-config",
  name: 'high-seas-config',
  meta: {
    auth: true,
    title: '公海规则设置'
  },
  component: function component() {
    return import("@/pages/dsp/system-config/high-seas-config");
  }
  // beforeEnter: async (to, from, next) => {
  //     let isPermission = await getAuth("high-seas-config");
  //     if (isPermission) {
  //         next();
  //     } else {
  //         next({ name: "403" });
  //     }
  // },
};