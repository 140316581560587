export default {
  path: "/op/financial",
  title: "应收管理",
  children: [{
    path: "/op/financial/payment-plan",
    title: "应收收入",
    children: [{
      path: "/op/financial/payment-plan/detail",
      hide: true,
      title: "应收收入详情"
    }, {
      path: "/operate-bill/plan/edit",
      hide: true,
      title: "编辑计划"
    }, {
      path: "/operate-bill/plan/detail",
      hide: true,
      title: "账单详情"
    }, {
      path: "/monthly-bill/details",
      hide: true,
      title: "DSP账单详情"
    }]
  }, {
    path: "/op/financial/change-order",
    title: "应收变更单"
  },
  // {
  //     path: "/op/financial/collection-manage",
  //     title: "收款管理",
  //     children: [
  //         {
  //             path: "/op/financial/collection-manage/edit",
  //             title: "添加收款/编辑收款",
  //             hide: true,
  //         },
  //         {
  //             path: "/op/financial/collection-manage/detail",
  //             title: "收款详情",
  //             hide: true,
  //         },
  //     ],
  // },
  {
    path: "/op/financial/payment-manage",
    title: "回款管理",
    children: [{
      path: "/op/financial/payment-manage/detail",
      hide: true,
      title: "回款详情"
    }]
  },
  // {
  //     path: "/op/financial/payAdvance-plan",
  //     title: "收款计划",
  //     children: [
  //         {
  //             path: "/op/financial/payAdvance-plan/detail",
  //             hide: true,
  //             title: "收款计划详情",
  //         },
  //     ],
  // },
  {
    path: "/op/financial/writeoff-record",
    title: "销账记录"
  }]
};