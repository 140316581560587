/**
 * 该组件除了 Menu，也被 Breadcrumb 使用过
 * */
export default {
  name: 'iMenuHeadTitle',
  props: {
    item: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    hideIcon: {
      type: Boolean,
      default: false
    }
  }
};