export default {
  path: "/payable",
  title: "应付管理",
  children: [{
    path: "/payable/payment-payable",
    title: "应付付款",
    children: [{
      path: "/payable/payment-payable/detail",
      hide: true,
      title: "应付详情"
    }, {
      path: "/payable/amazon-bill/detail",
      hide: true,
      title: "账单详情"
    }]
  }
  // {
  //     path: "/payable/amazon-bill",
  //     title: "DSP亚马逊账单",
  //     children: [
  //         {
  //             path: "/payable/amazon-bill/detail",
  //             hide: true,
  //             title: "账单详情",
  //         },
  //     ],
  // },
  // {
  //     path: "/payable/paymoney-manage",
  //     title: "付款管理",
  //     children: [
  //         {
  //             path: "/payable/paymoney-manage/add-edit",
  //             title: "添加编辑付款/核销",
  //             hide: true,
  //         },
  //         {
  //             path: "/payable/paymoney-manage/detail",
  //             title: "付款/核销详情",
  //             hide: true,
  //         },
  //     ],
  // },
  // {
  //     path: "/payable/petty-cash-manage",
  //     title: "备用金管理",
  //     children: [
  // 		{
  // 		    path: "/payable/petty-cash-application/edit",
  // 		    title: "添加/编辑备用金申请",
  // 		    hide: true,
  // 		},
  // 		{
  // 		    path: "/payable/petty-cash-application/detail",
  // 		    title: "备用金申请详情",
  // 		    hide: true,
  // 		},
  //         {
  //             path: "/payable/petty-cash-return/edit",
  //             title: "添加/编辑备用金归还",
  //             hide: true,
  //         },
  //         {
  //             path: "/payable/petty-cash-return/detail",
  //             title: "备用金归还详情",
  //             hide: true,
  //         },
  //     ],
  // },
  ]
};