export default {
  name: 'fk-password',
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      isShowPassword: false
    };
  },
  computed: {
    type: function type() {
      return this.isShowPassword ? 'text' : 'password';
    }
  },
  methods: {
    //切换密码明文/密文
    toggleType: function toggleType() {
      this.isShowPassword = !this.isShowPassword;
    },
    onInput: function onInput(v) {
      this.$emit('input', v);
    },
    onClear: function onClear() {
      this.$emit('input', '');
    }
  }
};