import { getAuth } from "@/libs/auth";
export default {
  path: "/contract-manage/contract-list/edit",
  name: "contract-edit",
  meta: {
    auth: true,
    title: "编辑账单信息"
    // moduleId: 25,
    // childModuleId: 64,
  },
  component: function component() {
    return import("@/pages/dsp/contract-manage/edit");
  }
  // beforeEnter: async (to, from, next) => {
  //     let isPermission = await getAuth("contract-edit");
  //     if (isPermission) {
  //         next();
  //     } else {
  //         next({ name: "403" });
  //     }
  // },
};