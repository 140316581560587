import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.array.find";
import _defineProperty from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { mapState } from "vuex";
import util from "@/libs/util";
export default {
  name: 'account-info',
  props: ['row', 'hideSite', 'noJumpId', 'isAsin', 'jumpToDetailsPath'],
  data: function data() {
    return {};
  },
  computed: _objectSpread(_objectSpread({}, mapState('admin/site', ['sites'])), {}, {
    toolTipContentName: function toolTipContentName() {
      return this.row.advertiserName || this.row.advertiserRemark || this.row.saasAccountName;
    },
    toolTipContentId: function toolTipContentId() {
      return this.row.advertiserIdStr || this.row.sellerId || this.row.advertiserNo;
    },
    site: function site() {
      return this.row.site || this.row.siteName || this.row.advertiserSite;
    },
    href: function href() {
      if (this.isAsin) {
        var siteUrl = this.getSiteUrl();
        return "".concat(siteUrl, "sp?_encoding=UTF8&seller=").concat(this.row.sellerId, "&tab=&vasStoreID=");
      } else {
        var dspUrl = this.getDspUrl();
        return "".concat(dspUrl).concat(this.row.amazonEntityId, "/advertisers/").concat(this.toolTipContentId, "/orders");
      }
    }
  }),
  methods: {
    jumpToDetails: function jumpToDetails() {
      this.$router.push({
        name: this.jumpToDetailsPath,
        query: {
          id: this.row.id
        }
      });
    },
    getSiteUrl: function getSiteUrl() {
      var _this$sites$find,
        _this = this;
      return ((_this$sites$find = this.sites.find(function (v) {
        return util.equalIgnoreCase(v.site, _this.site);
      })) === null || _this$sites$find === void 0 ? void 0 : _this$sites$find.siteUrl) || '';
    },
    getDspUrl: function getDspUrl() {
      var _this$sites$find2,
        _this2 = this;
      return ((_this$sites$find2 = this.sites.find(function (v) {
        return util.equalIgnoreCase(v.site, _this2.site);
      })) === null || _this$sites$find2 === void 0 ? void 0 : _this$sites$find2.dspUrl) || '';
    }
  }
};