/*
 * @Author: wumingtao@4kmiles.com wumingtao@4kmiles.com
 * @Date: 2023-07-21 18:34:02
 * @LastEditors: wumingtao@4kmiles.com wumingtao@4kmiles.com
 * @LastEditTime: 2023-10-26 16:09:27
 * @FilePath: \fourkmiles-vue-4kweb\src\directives\loading\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue';
import Loading from "../components/fk/fk-loading";
export default {
  bind: function bind(el, _bind, vnode) {
    var Constructor = Vue.extend(Loading);
    var instance = new Constructor({
      propsData: {
        text: _bind.arg || null
      }
    }).$mount();
    instance.visible = _bind.value;
    el.insertBefore(instance.$el, el.firstChild);
    el._ht_loading_component = instance;
    el.style.position = 'relative';
  },
  update: function update(el, bind) {
    el._ht_loading_component.visible = bind.value;
    el._ht_loading_component.text = bind.arg;
  },
  unbind: function unbind(el) {
    el._ht_loading_component = undefined;
  }
};