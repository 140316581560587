import "core-js/modules/es6.regexp.replace";
import _toConsumableArray from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import BasicLayout from '@/layouts/basic-layout';
// 自动引入页面架构内所有路由模块
import pages from "./pages/index";
//页面架构外的【在新页面打开方式】打开的 详情页等其他页面
import details from "./details/index";
var frameIn = [{
  path: '/',
  redirect: {
    name: 'home'
  },
  meta: {
    auth: true
  },
  component: BasicLayout,
  children: [].concat(_toConsumableArray(pages), _toConsumableArray(details), [
  // 刷新页面 必须保留
  {
    path: 'refresh',
    name: 'refresh',
    hidden: true,
    component: {
      beforeRouteEnter: function beforeRouteEnter(to, from, next) {
        next(function (instance) {
          return instance.$router.replace(from.fullPath);
        });
      },
      render: function render(h) {
        return h();
      }
    }
  },
  // 页面重定向 必须保留
  {
    path: 'redirect/:route*',
    name: 'redirect',
    hidden: true,
    component: {
      beforeRouteEnter: function beforeRouteEnter(to, from, next) {
        next(function (instance) {
          return instance.$router.replace(JSON.parse(from.params.route));
        });
      },
      render: function render(h) {
        return h();
      }
    }
  }])
}];

/**
 * 在主框架之外显示
 */
var frameOut = [{
  path: '/'
}];

/**
 * 错误页面
 */

var errorPage = [{
  path: '/403',
  name: '403',
  component: function component() {
    return import('@/pages/system/error/403');
  }
}, {
  path: '/500',
  name: '500',
  component: function component() {
    return import('@/pages/system/error/500');
  }
}, {
  path: '*',
  name: '404',
  component: function component() {
    return import('@/pages/system/error/404');
  }
}];

// 导出需要显示菜单的
export var frameInRoutes = frameIn;

// 重新组织后导出
export default [].concat(frameIn, frameOut, errorPage);