var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("InputNumber", {
    attrs: {
      "active-change": _vm.activeChange,
      min: _vm.min,
      max: _vm.max,
      step: _vm.step,
      size: _vm.size,
      disabled: _vm.disabled,
      placeholder: _vm.placeholder,
      formatter: _vm.formatter,
      parser: _vm.parser,
      readonly: _vm.readonly,
      editable: _vm.editable,
      precision: _vm.precision,
      elementId: _vm.elementId,
      activeChange: _vm.activeChange
    },
    on: {
      "on-change": function onChange($event) {
        return _vm.$emit("on-change", $event);
      },
      "on-focus": function onFocus($event) {
        return _vm.$emit("on-focus", $event);
      },
      "on-blur": function onBlur($event) {
        return _vm.$emit("on-blur", $event);
      },
      input: function input($event) {
        return _vm.$emit("input", $event);
      }
    },
    model: {
      value: _vm.val,
      callback: function callback($$v) {
        _vm.val = $$v;
      },
      expression: "val"
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };