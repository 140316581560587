export default {
  namespaced: true,
  state: {
    tableId: '',
    isTried: false,
    promoterDrawer: false,
    flowPermission1: {},
    approverDrawer: false,
    approverConfig1: {},
    // 当前编辑的数据
    preApproverConfig1: {},
    // 父级编辑的数据
    copyerDrawer: false,
    copyerConfig1: {},
    conditionDrawer: false,
    conditionsConfig1: {
      conditionNodes: []
    }
  },
  mutations: {
    setTableId: function setTableId(status, payload) {
      status.tableId = payload;
    },
    setIsTried: function setIsTried(status, payload) {
      status.isTried = payload;
    },
    setPromoter: function setPromoter(status, payload) {
      status.promoterDrawer = payload;
    },
    setFlowPermission: function setFlowPermission(status, payload) {
      status.flowPermission1 = payload;
    },
    setApprover: function setApprover(status, payload) {
      status.approverDrawer = payload;
    },
    setApproverConfig: function setApproverConfig(status, payload) {
      status.approverConfig1 = payload;
    },
    setCopyer: function setCopyer(status, payload) {
      status.copyerDrawer = payload;
    },
    setCopyerConfig: function setCopyerConfig(status, payload) {
      status.copyerConfig1 = payload;
    },
    setCondition: function setCondition(status, payload) {
      status.conditionDrawer = payload;
    },
    setConditionsConfig: function setConditionsConfig(status, payload) {
      status.conditionsConfig1 = payload;
    },
    setPreApproverConfig1: function setPreApproverConfig1(status, payload) {
      status.preApproverConfig1 = payload;
    }
  },
  actions: {}
};