var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flex-eli"
  }, [_c("Tooltip", {
    attrs: {
      content: _vm.row.entityName,
      placement: "top",
      transfer: ""
    }
  }, [_c("div", {
    staticClass: "text-eli"
  }, [_c("span", [_vm._v(_vm._s(_vm.row.entityName))])])]), _c("Tooltip", {
    attrs: {
      content: _vm.row.amazonEntityId,
      placement: "top",
      transfer: ""
    }
  }, [_c("div", {
    staticClass: "text-eli"
  }, [_c("span", [_vm._v(_vm._s(_vm.row.amazonEntityId))])])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };