import Vue from 'vue';
import Vuex from 'vuex';
import admin from "./modules/admin";
// import business from './modules/business'
import filter from "./modules/filter";
Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    admin: admin,
    // business,
    filter: filter
  }
});