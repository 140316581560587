import request from '@/plugins/request';
import qs from 'qs';
export var getPrepare = function getPrepare() {
  return request.get("/companyManage/prepare");
};

// 列表
export var getCompanyPage = function getCompanyPage(params) {
  return request.post('/companyManage/list', params);
};
export var getCompanyPageForLead = function getCompanyPageForLead(params) {
  return request.post('/companyManage/getListForLead', params);
};

// 标记为主体公司
export var setMainCompany = function setMainCompany(id) {
  return request.put("/companyManage/markMainCompanyFlag/".concat(id, "/1"));
};

// 删除公司
export var deleteCompany = function deleteCompany(id) {
  return request.put("/companyManage/delete/".concat(id, "/1"));
};

// 编辑/审核查询详情
export var getCompanyInfoEdit = function getCompanyInfoEdit(id) {
  return request.get("/companyManage/info/".concat(id));
};

// 审核通过
export var approve = function approve(params) {
  return request.put("/companyManage/pass/status", params);
};

// 驳回
export var reject = function reject(params) {
  return request.put("/companyManage/reject/status", params);
};

// 仅保存
export var saveOnly = function saveOnly(params) {
  return request.post("/companyManage/saveOnly", params);
};

// 新增提交
export var addCompany = function addCompany(params) {
  return request.post('/companyManage/add', params);
};

// 编辑提交
export var editCompany = function editCompany(params) {
  return request.put('/companyManage/update', params);
};

// 详情页面查询
export var getCompanyDetails = function getCompanyDetails(id) {
  return request.get("/companyManage/details/".concat(id));
};

// 详情工商信息
export var getCompanyDetailsCommercial = function getCompanyDetailsCommercial(id) {
  return request.get("/companyManage/details/commercialList/".concat(id));
};

// name查重
export var checkNameExist = function checkNameExist(params) {
  return request.post("/companyManage/checkNameExist", params);
};

// 公司名称获取 模糊查询
export var getCompanyList = function getCompanyList(params) {
  return request.get("/companyManage/requestFuzzy", {
    params: params
  });
};
export var questContactFuzzy = function questContactFuzzy(params) {
  return request.get("/companyManage/requestContactFuzzy", {
    params: params
  });
};

// 公司信息查询 精确查询
export var getCompanyInfo = function getCompanyInfo(name) {
  return request.get("/companyManage/requestCompanyBase/".concat(name));
};

// 对象为4k时的账单信息
export var getInvoicesAccount = function getInvoicesAccount(id) {
  return request.get("/companyManage/details/invoicesAccount/".concat(id));
};

// 获取操作记录prepare
export var getCompanyLogPrepare = function getCompanyLogPrepare() {
  return request.get("/companyManage/log/prepare");
};

// 获取操作记录
export var getCompanyLog = function getCompanyLog(params) {
  return request.put("/companyManage/log", params);
};

// 编辑信用报告
export var editReportFile = function editReportFile(params) {
  return request.put('/companyManage/editCreditReportAndGuaranteeFile', params);
};

// sap 信息唯一性校验
export var uniquenessCheck = function uniquenessCheck(params) {
  return request.get("/companyManage/checkSapExist?".concat(qs.stringify(params)), {
    isMore: true
  });
};

// 导出公司列表
export var exportTableList = function exportTableList(params) {
  return request.post('/companyManage/export', params, {
    responseType: 'arraybuffer'
  });
};
export var uoloadCompanyExcel = function uoloadCompanyExcel(formData) {
  return request({
    method: "post",
    url: "/companyManage/import",
    headers: {
      'Content-type': 'application/json;charset=UTF-8'
    },
    data: formData
  });
};
// 批量插入客户数据
export var companyBatchExcel = function companyBatchExcel(data) {
  return request.post("/companyManage/import/submit", data);
};
export var editTicket = function editTicket(data) {
  return request.put("/companyManage/updateBill", data);
};
export var updateSap = function updateSap(data) {
  return request.put("/companyManage/updateSap", data);
};
export var importTemplate = function importTemplate() {
  var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    responseType: 'arraybuffer'
  };
  return request.post("/companyManage/importTemplate", {}, config);
};
export var errorMsgDownload = function errorMsgDownload(data) {
  var config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    responseType: 'arraybuffer'
  };
  return request.post("/companyManage/importErrorMsg/importPos", data, config);
};

// 客户公司 精准查询
export var getCompanyInfoClient = function getCompanyInfoClient(code) {
  return request.get("/companyManage/getCompanyNameByCode/".concat(code));
};