import request from '@/plugins/request';

// 获取自定义列
export var getFiledList = function getFiledList() {
  return request.get("/field");
};

// 修改自定义列
export var setCustomFields = function setCustomFields(params) {
  return request.post("/field/user", params);
};

// 获取货币单位
export var getCurrency = function getCurrency() {
  return request.get("/system/currency");
};

// 上传文件
export var uploadFile = function uploadFile(file, moduleId) {
  return request.post("/system/file?fileModelId=".concat(moduleId), file);
};

// 获取省市区数据
export var getAddressList = function getAddressList() {
  return request.get("/leads/area");
};

// get 获取大区站点相关信息
export var getAreaList = function getAreaList() {
  return request.get("/dsp/invoice/site");
};

// ba弹窗导出订单和fmdp
export var exportBdOrderListExcel = function exportBdOrderListExcel(filter) {
  var config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    responseType: 'arraybuffer'
  };
  return request.post("/performance/bd/list/bdOrderList/downLoad", filter, config);
};

// 公共接口 根据关键词搜索客户
export var getUserByBusiness = function getUserByBusiness(params) {
  return request.post("/common/search/customer", params);
};

// 公共接口 根据客户获取所属业务
export var getBusinessTypeByUser = function getBusinessTypeByUser(customerId) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return request.get("/common/search/businessType/".concat(customerId), {
    params: params
  });
};

// 获取企业微信组织架构的部门
export var getDepartment = function getDepartment() {
  return request.get("/common/search/department");
};

// 通过businessId获取单个业务
export var getBusinessTypeById = function getBusinessTypeById(businessId) {
  return request.get("/common/search/businessType/businessId/".concat(businessId));
};

// 记录下载附件的操作人信息
export var setDownloadInfo = function setDownloadInfo(id) {
  return request.get("/system/downloadInfo/".concat(id));
};

// 页面埋点
export var setMenuTrack = function setMenuTrack(params) {
  return request.post("/common/saveMenuTrack", params);
};

// 搜索所有员工
export var searchWxUser = function searchWxUser(params) {
  return request.post("/common/search/wxUser", params);
};

// 搜索GPM
export var searchGPM = function searchGPM(params) {
  return request.post("/common/search/gpm", params);
};

// 批量编辑申请人
export var changeApplyUser = function changeApplyUser(params) {
  return request.put("/fee/apply/update", params);
};

// 获取审批进程
export var getApprovalProcess = function getApprovalProcess(params) {
  return request.post("/wf/prc/detail", params);
};

// 获取操作权限和表单权限
export var getPermission = function getPermission(params, url) {
  return request.get(url ? url : '/fee/detail/permission', {
    params: params
  });
};

// 财务获取当前登录人是否审批人和申请人
export var isExamineOrApply = function isExamineOrApply(id) {
  return request.get("/fee/detail/check/".concat(id));
};

// 代运营账单获取当前登录人是否审批人和申请人
export var invoiceisExamineOrApply = function invoiceisExamineOrApply(id) {
  return request.get("/asaop/invoices/detail/check/".concat(id));
};

// 获取退回节点
export var getRollBackNodes = function getRollBackNodes(params, url) {
  return request.get(url ? url : '/fee/detail/sendBack/list', {
    params: params
  });
};

// 公共接口 根据关键词搜索客户 （不传入类型，默认查询客户，线索，对象）
export var getLeadsList = function getLeadsList(params) {
  return request.post("/common/search/leads", params);
};
export var updateReadStatus = function updateReadStatus(params) {
  return request.put("/fee/updateReadStatus", params);
};

// 打印财务单据
export var printDocument = function printDocument(id) {
  return request.post("/fee/print/".concat(id), null, {
    responseType: 'blob'
  });
};

// 获取公司主体
export var getCompanyByLeadsId = function getCompanyByLeadsId(params) {
  return request.post("/companyManage/getCompanyVerifyByLeadsId", params);
};

// 实时计算审核流
export var getApprovalProcessRealTime = function getApprovalProcessRealTime(params, url) {
  return request.post(url ? url : "/fee/detail/wf/prc", params);
};

// 实时计算金额(收款，付款，备用金费用单计算)
export var getFeeExchange = function getFeeExchange(params) {
  return request.post("/fee/exchange", params);
};

// 财务催办
export var urgeFee = function urgeFee(params) {
  return request.post("/fee/urge", params);
};

// 工作流状态页签 用章申请变更，用章申请
export var getWfPrepare = function getWfPrepare() {
  return request.get("/common/WfPrcBusinessSearchView");
};

// 快速审批
export var quickApproval = function quickApproval(params) {
  return request.put("/feishu/approval/quickApproval", params);
};

// 抄送人已读消息
export var updateReadStatusApproval = function updateReadStatusApproval(params) {
  return request.put("/feishu/approval/updateReadStatus", params);
};

// 用章转交人员
export var allUserInStaff = function allUserInStaff() {
  return request.get("/common/allUserInStaff");
};

// 获取合同模板
export var getContractTemplate = function getContractTemplate() {
  return request.get("/sealApply/templateList");
};

// 获取国家地区城市区
export var getCountryProvince = function getCountryProvince(params) {
  return request.get("/common/country", {
    params: params,
    isMore: true
  });
};
export var getSite = function getSite() {
  return request.get("/common/search/site/all");
};