import "core-js/modules/es6.number.constructor";
export default {
  name: "dsp-radio-group",
  props: {
    value: {
      type: Number,
      require: true
    },
    radioGroupOptions: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    size: {
      type: String,
      default: "small"
    },
    showArrow: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      model: null
    };
  },
  methods: {
    change: function change() {
      this.$emit("input", this.model);
    }
  },
  created: function created() {
    this.model = this.value;
  }
};