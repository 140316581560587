// 菜单，侧边栏
import home from "./home";
import intentionalCustomer from "./intentional-customer";
import customerManage from "./customer-manage";
import contractManage from "./contract-manage";
import financialManage from "./financial-manage";
import payableManage from "./payable-manage";
import applyManage from "./apply-manage";
import exchangeRateManage from "./exchangeRate-manage";
import systemConfig from "./system-config";
import logsManage from "./logs-manage";
import gpmManage from "./gpm-manage";
import statisticalAnalysis from "./statistical-analysis";
import saasStatistics from "./saas-statistics";
import globalStatistics from "./global-statistics";
import sdStatistics from "./sd-statistics";
import invoiceManage from "./invoice-manage";
import accountManage from "./account-manage";
import companyManage from "./company-manage";
export default [home, intentionalCustomer, customerManage, contractManage, gpmManage, financialManage, payableManage, invoiceManage,
// applyManage,
exchangeRateManage, companyManage, globalStatistics, statisticalAnalysis,
// saasStatistics,
// sdStatistics,
logsManage, accountManage, systemConfig];