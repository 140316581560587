export default {
  path: "/contract-manage",
  title: "订单管理",
  children: [{
    path: "/contract-manage/seal-application",
    title: "用章申请管理",
    children: [{
      path: "/seal-application/detail",
      hide: true,
      title: "用章申请详情"
    }, {
      path: "/contract-manage/seal-application/edit",
      title: "添加收款/编辑收款",
      hide: true
    }]
  }, {
    path: "/contract-manage/seal-change",
    title: "用章申请变更",
    children: [{
      path: "/seal-change/details",
      hide: true,
      title: "用章申请详情"
    }, {
      path: "/seal-change/edit",
      title: "新建/编辑用章申请变更",
      hide: true
    }]
  }, {
    path: "/contract-manage/contract-list",
    title: "订单",
    children: [{
      path: "/contract-manage/detail",
      hide: true,
      title: "订单详情"
    }, {
      path: "/order-notice",
      hide: true,
      title: "订单提醒"
    }, {
      path: "/contract-manage/contract-list/edit",
      hide: true,
      title: "编辑账单信息"
    }]
  }, {
    path: "/contract-manage/fmdp",
    title: "FMDP预算",
    name: "fmdp"
  }]
};