import iMenuSideTitle from "./menu-title";
export default {
  name: 'iMenuSideItem',
  components: {
    iMenuSideTitle: iMenuSideTitle
  },
  props: {
    menu: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    hideTitle: {
      type: Boolean,
      default: false
    }
  }
};