var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("Input", {
    attrs: {
      value: _vm.value,
      type: _vm.type,
      clearable: ""
    },
    on: {
      input: _vm.onInput,
      "on-clear": _vm.onClear
    }
  }, [_vm.isShowPassword ? _c("Icon", {
    attrs: {
      slot: "prepend",
      type: "ios-eye",
      size: "20"
    },
    on: {
      click: _vm.toggleType
    },
    slot: "prepend"
  }) : _c("Icon", {
    attrs: {
      slot: "prepend",
      type: "ios-eye-off",
      size: "20"
    },
    on: {
      click: _vm.toggleType
    },
    slot: "prepend"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };