var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("GlobalFooter", {
    staticClass: "i-copyright",
    attrs: {
      links: _vm.links,
      copyright: _vm.copyright
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };