import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.number.constructor";
export default {
  name: 'custom-form-table',
  props: {
    customFieldList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    showLast: function showLast() {
      var num = this.customFieldList.length % 2;
      return num == 0 ? false : true;
    }
  },
  filters: {
    formatNumbers: function formatNumbers(value, decimalPlace) {
      var num = Number(value).toFixed(decimalPlace);
      return num.replace(/(\d{1,3})(?=(?:\d{3})+\.)/g, '$1,');
    },
    formatMoneys: function formatMoneys(value, unit, decimalPlace) {
      var num = Number(value).toFixed(decimalPlace);
      return "".concat(unit ? unit : '') + ' ' + num.replace(/(\d{1,3})(?=(?:\d{3})+\.)/g, '$1,');
    }
  },
  methods: {}
};