export default {
  name: 'instructions-modal',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    visibleChange: function visibleChange(v) {
      this.$emit('input', v);
    },
    ok: function ok() {
      this.$emit('input', false);
    }
  }
};