export default {
  name: 'authority-dropdown',
  data: function data() {
    return {
      dropdownListLength: 1
    };
  },
  mounted: function mounted() {
    var _this = this;
    // 动态监听下拉子元素，根据长度确定是否显示
    this.$nextTick(function () {
      _this.dropdownListLength = _this.$refs['dropdown-list'].$children.length;
    });
  },
  updated: function updated() {
    var _this2 = this;
    // 动态监听下拉子元素，根据长度确定是否显示
    this.$nextTick(function () {
      _this2.dropdownListLength = _this2.$refs['dropdown-list'].$children.length;
    });
  }
};