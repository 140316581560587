var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "fk-chart-component",
    style: {
      height: _vm.height + "px"
    },
    on: {
      mouseleave: function mouseleave(e) {
        return _vm.$emit("mouseleave", e);
      }
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };