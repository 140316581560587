import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
/**
 * 此文件不应被修改
 * 此文件夹下的组件将会自动注册为全局组件
 * 编写规则：组件name属性将作为全局组件名称，必须拥有，且不能与其他组件相同
 */
function importAll(r) {
  return function (Vue) {
    r.keys().map(function (module) {
      return r(module).default;
    }).forEach(function (component) {
      return Vue.component(component.name, component);
    });
  };
}
export default importAll(require.context('./', true, /index\.vue$/));