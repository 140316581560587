var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", {
    staticClass: "i-layout-header-trigger i-layout-header-trigger-min"
  }, [_c("Dropdown", {
    staticClass: "i-layout-header-user",
    class: {
      "i-layout-header-user-mobile": _vm.isMobile
    },
    attrs: {
      trigger: _vm.isMobile ? "click" : "hover"
    },
    on: {
      "on-click": _vm.handleClick
    }
  }, [_c("Avatar", {
    attrs: {
      src: _vm.info.thumbAvatar
    }
  }), !_vm.isMobile ? _c("span", {
    staticClass: "i-layout-header-user-name"
  }, [_vm._v(_vm._s(_vm.info.wxName))]) : _vm._e(), _c("DropdownMenu", {
    attrs: {
      slot: "list"
    },
    slot: "list"
  }, [_c("DropdownItem", {
    attrs: {
      name: "password-reset"
    }
  }, [_c("Icon", {
    attrs: {
      type: "md-refresh"
    }
  }), _c("span", [_vm._v("修改密码")])], 1), _c("DropdownItem", {
    attrs: {
      divided: "",
      name: "logout"
    }
  }, [_c("Icon", {
    attrs: {
      type: "ios-log-out"
    }
  }), _c("span", [_vm._v("退出登录")])], 1)], 1)], 1), _c("iPasswordReset", {
    model: {
      value: _vm.isShowPasswdModal,
      callback: function callback($$v) {
        _vm.isShowPasswdModal = $$v;
      },
      expression: "isShowPasswdModal"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };