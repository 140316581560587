import "core-js/modules/es6.function.name";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "fk-province-city-wrapper",
    class: _vm.boxTypeClass
  }, [_vm._l(_vm.typeListOption, function (select) {
    return [select.type <= _vm.limitLevel ? _c("Select", {
      key: "fk-province-city" + select.type,
      attrs: {
        clearable: _vm.clearable,
        "filter-by-label": _vm.filterByLabel,
        filterable: _vm.filterable,
        loading: select.loading,
        placeholder: select.desc,
        transfer: _vm.transfer
      },
      on: {
        "on-select": function onSelect($event) {
          return _vm.onChange(select.type + 1, $event);
        },
        "on-clear": function onClear($event) {
          return _vm.onClear(select.type);
        }
      },
      model: {
        value: _vm.form[select.valueKey],
        callback: function callback($$v) {
          _vm.$set(_vm.form, select.valueKey, $$v);
        },
        expression: "form[select.valueKey]"
      }
    }, _vm._l(_vm.formList[select.listKey], function (item) {
      return _c("Option", {
        key: item.name + item.code,
        attrs: {
          label: item.name,
          value: item.code
        }
      });
    }), 1) : _vm._e()];
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };