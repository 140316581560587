export default {
  path: "/statistical-analysis",
  title: "DSP统计",
  children: [{
    path: "/statistical-analysis/overview-data",
    title: "数据总览"
  },
  // {
  //     path: "/statistical-analysis/customer-statistical",
  //     title: "客户统计",
  // },
  // {
  //     path: "/statistical-analysis/customer-source",
  //     title: "客户来源分析",
  // },
  // {
  //     path: "/statistical-analysis/customer-classification",
  //     title: "客户分类",
  //     children: [
  //         {
  //             path: "/statistical-analysis/customer-classification/detail",
  //             title: "客户分类详情",
  //             hide: true,
  //         },
  //     ],
  // },
  {
    path: "/statistical-analysis/fmdp-achievement-rate",
    title: "FMDP达成率"
  }, {
    path: "/statistical-analysis/media-plan-manage",
    title: "广告表现"
  }
  // {
  //     path: "/statistical-analysis/ba-statistical",
  //     title: "客户投放状态",
  // },
  // {
  //     path: "/statistical-analysis/financial-statistic",
  //     title: "广告主财务",
  // },
  ]
};