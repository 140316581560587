var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("DatePicker", {
    attrs: {
      editable: _vm.editable,
      clearable: "",
      "split-panels": _vm.splitPanels,
      transfer: _vm.transfer,
      type: _vm.type,
      options: _vm.options,
      placeholder: _vm.placeholder
    },
    on: {
      "on-change": _vm.dateChange
    },
    model: {
      value: _vm.date,
      callback: function callback($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };