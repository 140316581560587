export default {
  path: "/company-manage",
  title: "公司管理",
  children: [{
    path: "/company-manage/company-client",
    title: "客户公司",
    children: [{
      path: "/company-manage/company-client/create",
      hide: true,
      title: "新建客户公司"
    }, {
      path: "/company-manage/company-client/ad-ticket-qualifications",
      hide: true,
      title: "编辑发票抬头"
    }, {
      path: "/company-manage/company-client/details",
      hide: true,
      title: "客户公司详情"
    }, {
      path: "/company-manage/company-client/review",
      hide: true,
      title: "审核客户公司"
    }, {
      path: "/company-manage/company-client/edit",
      hide: true,
      title: "编辑客户公司"
    }]
  }, {
    path: "/company-manage/company-fk",
    title: "4K公司",
    children: [{
      path: "/company-manage/company-fk/create",
      hide: true,
      title: "新建4K公司"
    }, {
      path: "/company-manage/company-fk/details",
      hide: true,
      title: "4K公司详情"
    }, {
      path: "/company-manage/company-fk/review",
      hide: true,
      title: "审核4K公司"
    }, {
      path: "/company-manage/company-fk/edit",
      hide: true,
      title: "编辑4K公司"
    }]
  }]
};