var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", {
    staticClass: "i-layout-header-trigger",
    on: {
      click: _vm.handleToggleMenuSide
    }
  }, [_c("Icon", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.menuCollapse || _vm.isMobile,
      expression: "menuCollapse || isMobile"
    }],
    attrs: {
      custom: "i-icon i-icon-menu-unfold"
    }
  }), _c("Icon", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.menuCollapse && !_vm.isMobile,
      expression: "!menuCollapse && !isMobile"
    }],
    attrs: {
      custom: "i-icon i-icon-menu-fold"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };