import { getAuth, getAuthority } from "@/libs/auth";
export default {
  path: "/company-manage/company-fk/review",
  name: "company-fk-review",
  meta: {
    auth: true,
    title: "审核4K公司"
  },
  component: function component() {
    return import("@/pages/dsp/company-manage/company-fk/review");
  }
  // beforeEnter: async (to, from, next) => {
  //     let isPermission = await getAuthority(782);
  //     if (isPermission) {
  //         next();
  //     } else {
  //         next({ name: "403" });
  //     }
  // },
};