import dayjs from 'dayjs';

// 根据日期获取某周的开始日期
export function getWeekStartDate(date) {
  var now = new Date(date);
  var nowDayOfWeek = now.getDay(); //今天本周的第几天
  var nowDay = now.getDate(); //当前日
  var nowMonth = now.getMonth(); //当前月     
  var nowYear = now.getYear(); //当前年     
  nowYear += nowYear < 2000 ? 1900 : 0; // 
  var weekStartDate = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek);
  return dayjs(weekStartDate).format('YYYY-MM-DD');
}

// 根据日期获取某周的结束日期
export function getWeekEndDate(date) {
  var now = new Date(date);
  var nowDayOfWeek = now.getDay(); //今天本周的第几天
  var nowDay = now.getDate(); //当前日
  var nowMonth = now.getMonth(); //当前月     
  var nowYear = now.getYear(); //当前年     
  nowYear += nowYear < 2000 ? 1900 : 0; // 
  var weekEndDate = new Date(nowYear, nowMonth, nowDay + (6 - nowDayOfWeek));
  return dayjs(weekEndDate).format('YYYY-MM-DD');
}