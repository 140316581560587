var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("router-link", {
    attrs: {
      to: {
        name: "portraitdetail",
        params: {
          UserId: _vm.userId
        }
      },
      target: "_blank"
    }
  }, [_vm._v("\n\t" + _vm._s(_vm.userName) + "\n")]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };