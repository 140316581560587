import "core-js/modules/es6.array.find";
export default {
  name: 'advertiser-info',
  props: ['row', 'areaList', 'hideSite', 'noJumpId'],
  data: function data() {
    return {};
  },
  computed: {
    // siteComputed(){
    // 	if(this.areaList){
    // 		let sites = this.areaList.reduce((pre,v)=>{
    // 			pre = [...v.sites,...pre];
    // 			return pre;
    // 		},[])
    // 		return sites.find(v=>v.id === this.row.siteId).name;
    // 	}else{
    // 		return '';
    // 	}
    // }
  },
  methods: {
    getArea: function getArea(areaId) {
      return this.areaList && this.areaList.find(function (i) {
        return i.id === areaId;
      }) || {};
    }
  }
};