var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", {
    staticClass: "i-layout-menu-side-title break-all",
    staticStyle: {
      "white-space": "normal",
      "padding-right": "3px"
    }
  }, [_vm.menu.icon || _vm.menu.custom || _vm.menu.img ? _c("span", {
    staticClass: "i-layout-menu-side-title-icon",
    class: {
      "i-layout-menu-side-title-icon-single": _vm.hideTitle
    }
  }, [_vm.menu.icon ? _c("Icon", {
    attrs: {
      type: _vm.menu.icon
    }
  }) : _vm.menu.custom ? _c("Icon", {
    attrs: {
      custom: _vm.menu.custom
    }
  }) : _vm.menu.img ? _c("img", {
    attrs: {
      src: _vm.menu.img
    }
  }) : _vm._e()], 1) : _vm._e(), !_vm.hideTitle ? _c("span", {
    staticClass: "i-layout-menu-side-title-text",
    class: {
      "i-layout-menu-side-title-text-selected": _vm.selected
    }
  }, [_vm._v(_vm._s(_vm.menu.title))]) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };