import request from '@/plugins/request';

/**
 * 获取 DSP上传列表
 */
export var getLogList = function getLogList() {
  var filter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    method: 'post',
    url: "/log/list",
    data: filter
  });
};

/**
 * 获取 上传列表
 */
export var getUploadList = function getUploadList() {
  var filter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    method: 'post',
    url: "/upload/list",
    data: filter
  });
};
export var pull = function pull() {
  var filter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    method: 'post',
    url: "/log/pull",
    data: filter
  });
};

/**
 * 获取 上传页预加载数据
 */
export var getLogPrepara = function getLogPrepara(isApi) {
  return request({
    method: 'get',
    url: "/log/prepara?isApi=" + isApi
  });
};

/**
 * 获取 entity列表
*/
export var getEntityList = function getEntityList(entityType) {
  return request({
    method: 'get',
    url: "/log/entity/list/".concat(entityType)
  });
};

//获取七牛云上传token
export var getQiniuUpToken = function getQiniuUpToken() {
  return request.get("/order/getUpToken");
};

//获取报告管理  七牛云上传图片token
export var getQiniuImgToken = function getQiniuImgToken() {
  return request.get("/report/up/token");
};