var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return !_vm.isMobile ? _c("span", {
    staticClass: "i-layout-header-trigger i-layout-header-trigger-min i-layout-header-trigger-in i-layout-header-trigger-nohover"
  }, [_c("input", {
    staticClass: "i-layout-header-search",
    attrs: {
      type: "text",
      placeholder: "搜索..."
    }
  })]) : _c("span", {
    staticClass: "i-layout-header-trigger i-layout-header-trigger-min"
  }, [_c("Dropdown", {
    ref: "dropdown",
    staticClass: "i-layout-header-search-drop",
    attrs: {
      trigger: "click"
    }
  }, [_c("Icon", {
    attrs: {
      type: "ios-search"
    }
  }), _c("DropdownMenu", {
    attrs: {
      slot: "list"
    },
    slot: "list"
  }, [_c("div", {
    staticClass: "i-layout-header-search-drop-main"
  }, [_c("Input", {
    attrs: {
      size: "large",
      prefix: "ios-search",
      type: "text",
      placeholder: "搜索..."
    }
  }), _c("span", {
    staticClass: "i-layout-header-search-drop-main-cancel",
    on: {
      click: _vm.handleCloseSearch
    }
  }, [_vm._v("取消")])], 1)])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };