import "core-js/modules/es6.function.name";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("Modal", {
    staticClass: "customModal",
    attrs: {
      title: "自定义字段",
      "mask-closable": false,
      loading: true,
      "footer-hide": true,
      transfer: "",
      width: 500
    },
    on: {
      "on-visible-change": _vm.visiableChange
    },
    model: {
      value: _vm.showModal,
      callback: function callback($$v) {
        _vm.showModal = $$v;
      },
      expression: "showModal"
    }
  }, [_c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "left"
  }, [_c("ul", [_c("li", {
    class: {
      current: _vm.currentId === 0
    },
    on: {
      click: function click($event) {
        _vm.currentId = 0;
      }
    }
  }, [_vm._v("\n          全部")]), _vm._l(_vm.leftList, function (leftV, leftI) {
    return _c("li", {
      key: leftI,
      class: {
        current: _vm.currentId === leftV.id
      },
      on: {
        click: function click($event) {
          _vm.currentId = leftV.id;
        }
      }
    }, [_vm._v("\n            " + _vm._s(leftV.name) + "\n          ")]);
  })], 2)]), _c("div", {
    staticClass: "right"
  }, [_c("div", {
    staticClass: "item"
  }, [_c("Checkbox", {
    on: {
      "on-change": _vm.checkChangeAll
    },
    model: {
      value: _vm.checkAll,
      callback: function callback($$v) {
        _vm.checkAll = $$v;
      },
      expression: "checkAll"
    }
  }, [_vm._v("全选\n\t\t\t")]), _vm._l(_vm.deafultColumns, function (item, index) {
    return [item.tagId === _vm.currentId || _vm.currentId === 0 ? _c("Checkbox", {
      key: index + item.key,
      attrs: {
        disabled: item.isFixed
      },
      on: {
        "on-change": function onChange($event) {
          return _vm.checkChange(item);
        }
      },
      model: {
        value: item.isChoose,
        callback: function callback($$v) {
          _vm.$set(item, "isChoose", $$v);
        },
        expression: "item.isChoose"
      }
    }, [_vm._v(_vm._s(item.name))]) : _vm._e()];
  })], 2)])]), _c("div", {
    staticClass: "btnBox"
  }, [_c("Button", {
    attrs: {
      type: "default",
      disabled: _vm.loading
    },
    on: {
      click: _vm.cancel
    }
  }, [_vm._v("取消")]), _c("Button", {
    staticStyle: {
      "margin-left": "15px"
    },
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.ok
    }
  }, [_vm._v("确认")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };