var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flex-align-center"
  }, [!_vm.hideSite ? _c("dsp-site-icon", {
    attrs: {
      site: _vm.row.site || _vm.row.siteName || _vm.row.advertiserSite || _vm.row.siteId,
      theme: "dark"
    }
  }) : _vm._e(), _c("div", {
    staticClass: "flex-eli",
    class: {
      "ml-10": !_vm.hideSite
    }
  }, [_c("div", {
    staticStyle: {
      "text-align": "left"
    }
  }, [_c("Tooltip", {
    attrs: {
      content: _vm.row.advertiserName || _vm.row.advertiserRemark,
      placement: "top",
      transfer: "",
      "max-width": "200"
    }
  }, [_c("div", {
    staticClass: "txtEli",
    on: {
      contextmenu: function contextmenu($event) {
        $event.preventDefault();
        return _vm.$Copy({
          text: _vm.row.advertiserName || _vm.row.advertiserRemark
        });
      }
    }
  }, [_vm._v("\n\t\t\t\t\t" + _vm._s(_vm.row.advertiserName || _vm.row.advertiserRemark) + "\n\t\t\t\t")])])], 1), _c("div", {
    staticStyle: {
      "text-align": "left"
    }
  }, [_c("Tooltip", {
    attrs: {
      content: _vm.row.advertiserIdStr || _vm.row.advertiserNo,
      placement: "top",
      transfer: ""
    }
  }, [_c("div", {
    staticClass: "txtEli"
  }, [!_vm.noJumpId ? _c("a", {
    attrs: {
      href: "".concat(_vm.getArea(_vm.row.areaId).dspUrl).concat(_vm.row.amazonEntityId || _vm.row.advertiserNo, "/advertisers/").concat(_vm.row.advertiserIdStr || _vm.row.advertiserNo, "/orders"),
      target: "_blank"
    },
    on: {
      contextmenu: function contextmenu($event) {
        $event.preventDefault();
        return _vm.$Copy({
          text: _vm.row.advertiserIdStr || _vm.row.advertiserNo
        });
      }
    }
  }, [_vm._v("\n\t\t\t\t\t\t" + _vm._s(_vm.row.advertiserIdStr || _vm.row.advertiserNo) + "\n\t\t\t\t\t")]) : _c("pre", {
    staticClass: "text-eli",
    on: {
      contextmenu: function contextmenu($event) {
        $event.preventDefault();
        return _vm.$Copy({
          text: _vm.row.advertiserIdStr || _vm.row.advertiserNo
        });
      }
    }
  }, [_vm._v(_vm._s(_vm.row.advertiserIdStr || _vm.row.advertiserNo))])])])], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };