import "core-js/modules/es6.string.fixed";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visible,
      expression: "visible"
    }],
    staticClass: "fk-loading-component",
    class: {
      fixed: _vm.fixed
    }
  }, [_c("Icon", {
    attrs: {
      type: "ios-loading fk-loading-image",
      size: 18,
      color: "#2d8cf0"
    }
  }), _c("span", [_vm._v(_vm._s(_vm.text || _vm.loadingText))])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };