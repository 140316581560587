import "core-js/modules/es6.function.name";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "content"
  }, [_c("div", {
    staticClass: "profile-info-wrap custom-table"
  }, [_c("table", [_c("tbody", [_c("tr", [_c("td", [_vm._v("公司名称")]), _c("td", [_vm._v(_vm._s(_vm.companyInfo.name || "暂无信息"))])]), _c("tr", [_c("td", [_vm._v("统一社会信用代码")]), _c("td", [_vm._v(_vm._s(_vm.companyInfo.creditNo || "暂无信息"))])]), _c("tr", [_c("td", [_vm._v("法定代表人")]), _c("td", [_vm._v(_vm._s(_vm.companyInfo.legalPersonName || "暂无信息"))])]), _c("tr", [_c("td", [_vm._v("注册地址")]), _c("td", [_vm._v(_vm._s(_vm.companyInfo.address || "暂无信息"))])]), _c("tr", [_c("td", [_vm._v("成立日期")]), _c("td", [_vm._v(_vm._s(_vm.companyInfo.startDate || "暂无信息"))])]), _c("tr", [_c("td", [_vm._v("注册资本")]), _c("td", [_vm._v(_vm._s(_vm.companyInfo.registerCapital || "暂无信息"))])]), _c("tr", [_c("td", [_vm._v("经营状态")]), _c("td", [_vm._v(_vm._s(_vm.companyInfo.registerStatus || "暂无信息"))])]), _c("tr", [_c("td", [_vm._v("币种")]), _c("td", [_vm._v(_vm._s(_vm.companyInfo.registerCapitalCurrency || "暂无信息"))])]), _c("tr", [_c("td", [_vm._v("营业期限")]), _c("td", [_vm._v(_vm._s(_vm.companyInfo.businessTerm || "暂无信息"))])]), _c("tr", [_c("td", [_vm._v("登记机关")]), _c("td", [_vm._v(_vm._s(_vm.companyInfo.belongOrg || "暂无信息"))])]), _c("tr", [_c("td", [_vm._v("公司类型")]), _c("td", [_vm._v(_vm._s(_vm.companyInfo.regType || "暂无信息"))])]), _c("tr", [_c("td", [_vm._v("工商注册号")]), _c("td", [_vm._v(_vm._s(_vm.companyInfo.registerNo || "暂无信息"))])]), _c("tr", [_c("td", [_vm._v("经营范围")]), _c("td", [_vm._v(_vm._s(_vm.companyInfo.businessScope || "暂无信息"))])])])])]), !_vm.loading && _vm.showBtn ? _c("div", {
    staticClass: "btn"
  }, [_c("Button", {
    attrs: {
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        return _vm.$emit("close");
      }
    }
  }, [_vm._v("关闭")]), _c("Button", {
    staticClass: "ml-10",
    attrs: {
      loading: _vm.loading,
      type: "primary"
    },
    on: {
      click: _vm.onOk
    }
  }, [_vm._v("回填工商信息")])], 1) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };