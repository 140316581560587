import { getAuth } from "@/libs/auth";
export default {
  path: "/system-config/virtual-account-manage",
  name: "virtual-account-manage",
  meta: {
    auth: true,
    title: "虚拟账号"
  },
  component: function component() {
    return import("@/pages/dsp/system-config/virtual-account-manage");
  }
  // beforeEnter: async (to, from, next) => {
  //     let isPermission = await getAuth("virtual-account-manage");
  //     if (isPermission) {
  //         next();
  //     } else {
  //         next({ name: "403" });
  //     }
  // },
};