import "core-js/modules/es6.number.constructor";
export default {
  name: 'fk-page-wrapper',
  props: {
    maxWidth: {
      type: [String, Number]
    }
  },
  computed: {
    style: function style() {
      var style = {};
      var maxWidth = this.maxWidth;
      if (maxWidth) style.maxWidth = "".concat(maxWidth, "px");
      return style;
    }
  }
};