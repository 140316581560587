export default {
  path: '/exchangeRate',
  title: '汇率管理',
  children: [{
    path: '/exchangeRate/exchangeRate-manage',
    title: '汇率管理'
  }
  // {
  // 	path: '/exchangeRate/exchange-loss-manage',
  // 	title: '汇损管理'
  // },
  ]
};