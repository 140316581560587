import "core-js/modules/es6.number.constructor";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
export default {
  name: 'fk-pop-change',
  props: {
    title: {
      type: String,
      default: '修改内容'
    },
    content: {
      type: String,
      default: ''
    },
    size: {
      validator: function validator(value) {
        return ['small', 'large', 'default'].includes(value);
      },
      default: 'small'
    },
    width: {
      type: Number,
      default: 300
    },
    placeholder: {
      type: String,
      default: '输入新的值'
    },
    placement: {
      validator: function validator(value) {
        return ['top', 'top-start', 'top-end', 'bottom', 'bottom-start', 'bottom-end', 'left', 'left-start', 'left-end', 'right', 'right-start', 'right-end'].includes(value);
      },
      default: 'top'
    }
  },
  data: function data() {
    return {
      value: this.content
    };
  },
  watch: {
    content: function content(newContent) {
      this.value = newContent;
    }
  }
};