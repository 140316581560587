import { getAuthority } from "@/libs/auth";
export default {
  path: "/system-config/group-email/edit-bill-to-party/:id",
  name: "edit-bill-to-party",
  meta: {
    auth: true,
    title: "编辑Bill to party"
  },
  component: function component() {
    return import("@/pages/dsp/system-config/group-email/edit-bill-to-party");
  }
  // beforeEnter: async (to, from, next) => {
  //     let isPermission = await getAuthority(778);
  //     if (isPermission) {
  //         next();
  //     } else {
  //         next({ name: "403" });
  //     }
  // },
};