import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.replace";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "i-layout-menu-head",
    class: {
      "i-layout-menu-head-mobile": _vm.isMobile
    }
  }, [!_vm.isMobile && !_vm.isMenuLimit ? _c("Menu", {
    ref: "menu",
    attrs: {
      mode: "horizontal",
      "active-name": _vm.headerName
    }
  }, _vm._l(_vm.filterHeader, function (item) {
    return _c("MenuItem", {
      key: item.path,
      attrs: {
        to: item.path,
        replace: item.replace,
        target: item.target,
        name: item.name
      }
    }, [_c("i-menu-head-title", {
      attrs: {
        item: item
      }
    })], 1);
  }), 1) : _c("div", {
    staticClass: "i-layout-header-trigger i-layout-header-trigger-min i-layout-header-trigger-in i-layout-header-trigger-no-height"
  }, [_c("Dropdown", {
    class: {
      "i-layout-menu-head-mobile-drop": _vm.isMobile
    },
    attrs: {
      trigger: "click"
    }
  }, [_c("Icon", {
    attrs: {
      type: "ios-apps"
    }
  }), _c("DropdownMenu", {
    attrs: {
      slot: "list"
    },
    slot: "list"
  }, _vm._l(_vm.filterHeader, function (item) {
    return _c("i-link", {
      key: item.path,
      attrs: {
        to: item.path,
        replace: item.replace,
        target: item.target
      }
    }, [_c("DropdownItem", [_c("i-menu-head-title", {
      attrs: {
        item: item
      }
    })], 1)], 1);
  }), 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };