export default {
  path: "/system-config",
  title: "系统设置",
  children: [{
    path: "/system-config/account-manage",
    title: "账户管理"
  }, {
    path: "/system-config/business-manage",
    title: "业务管理",
    children: [{
      path: "/business-manage/detail",
      hide: true,
      title: "业务管理详情"
    }]
  }, {
    path: "/system-config/personnel-manage",
    title: "人员管理"
  },
  // {
  //     path: "/system-config/platform-manage",
  //     title: "对象管理",
  // },
  {
    path: "/system-config/general-config",
    title: "常规设置"
  }, {
    path: "/system-config/contract-config",
    title: "订单设置"
  }, {
    path: "/system-config/financial-config",
    title: "财务设置"
  }, {
    path: "/system-config/dsp-bill-setting",
    title: "广告主账单设置",
    children: [{
      path: "/system-config/dsp-bill-setting/edit",
      hide: true,
      title: "广告主账单设置"
    }, {
      path: "/system-config/dsp-bill-setting/details",
      hide: true,
      title: "DSP账单信息详情"
    }]
  }, {
    path: "/system-config/work-flow",
    title: "工作流设置",
    children: [{
      path: "/work-flow/setting",
      hide: true,
      title: "编辑工作流"
    }]
  }, {
    path: "/system-config/approval-config",
    title: "审批设置"
  }, {
    path: "/system-config/seal-config",
    title: "印章设置"
  }, {
    path: "/system-config/commerce-manage",
    title: "商务管理"
  }, {
    path: "/system-config/make-out-invoice",
    title: "开票设置"
  }, {
    path: "/system-config/tag-manage",
    title: "标签管理"
  }, {
    path: "/system-config/high-seas-config",
    title: "公海规则设置"
  }, {
    path: "/system-config/virtual-account-manage",
    title: "虚拟账号"
  }, {
    path: "/system-config/group-email",
    title: "邮件组管理",
    children: [{
      path: "/system-config/group-email/create",
      hide: true,
      title: "新建邮件组"
    }, {
      path: "/system-config/group-email/edit",
      hide: true,
      title: "编辑邮件组"
    }]
  }]
};