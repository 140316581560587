var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "chart",
    staticClass: "chart",
    style: {
      height: "".concat(_vm.height, "px")
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };