import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.number.constructor";
export default {
  name: 'fk-input-percent',
  props: {
    unit: {
      type: String,
      default: ''
    },
    //货币单位
    //以下是数字框自带的默认设置
    value: {
      type: Number
    },
    activeChange: {
      type: Boolean,
      default: false
    },
    //防止设置小数位数之后，每次输入光标都跳转到最后，极度影响体验
    max: {
      type: Number
    },
    min: {
      type: Number
    },
    step: {
      type: Number,
      default: 1
    },
    size: {
      validator: function validator(v) {
        return ['small', 'default', 'large'].includes(v);
      },
      default: 'default'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    readonly: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: true
    },
    precision: {
      type: Number
    },
    elementId: {
      type: String
    }
  },
  data: function data() {
    return {
      val: this.value
    };
  },
  methods: {
    formatter: function formatter(value) {
      return "".concat(value, "%");
    },
    parser: function parser(value) {
      return value.replace('%', '');
    }
  },
  watch: {
    value: function value(newVal) {
      this.val = newVal;
    },
    val: function val(newVal) {
      this.$emit('input', newVal);
    }
  }
};