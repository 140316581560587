import { getAuthority, getExamineOrApply } from "@/libs/auth";
export default {
  path: "/seal-change/edit",
  name: "seal-change-edit",
  meta: {
    auth: true,
    title: "新建/编辑/审批变更"
  },
  component: function component() {
    return import("@/pages/dsp/contract-manage/seal-change/edit");
  }
  // beforeEnter: async (to, from, next) => {
  //     let isPermission;
  //     if(to.query.id){
  //         isPermission = await getExamineOrApply(to.query.id);
  //     }else{
  //         isPermission = await getAuthority(263);
  //     }
  //     if (isPermission) {
  //         next();
  //     } else {
  //         next({ name: "403" });
  //     }
  // },
};