import "core-js/modules/es6.number.constructor";
export default {
  name: 'fk-star',
  props: {
    levels: {
      type: Number,
      default: 5
    },
    value: {
      type: Number,
      default: 0
    },
    size: {
      type: [Number, String],
      default: 20
    }
  },
  data: function data() {
    return {
      level: this.value
    };
  },
  methods: {
    update: function update(level) {
      this.level = level;
      this.$emit('input', level);
    }
  },
  watch: {
    value: function value(newValue) {
      this.level = newValue;
    }
  }
};