import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.array.find";
import "core-js/modules/es6.number.constructor";
export default {
  name: "fk-select-radio",
  props: {
    // 双向绑定的值
    value: {
      type: [String, Number],
      default: null
    },
    // 初始化显示的label
    defaultLabel: {
      type: String,
      default: ''
    },
    // label 要取的字段
    labelField: {
      type: String,
      default: 'name'
    },
    modelField: {
      type: String,
      default: 'id'
    },
    // 可选的list
    radioList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    onlyRead: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    defaultLabel: function defaultLabel(value) {
      this.label = value;
    }
  },
  data: function data() {
    return {
      label: this.defaultLabel,
      radioValue: null,
      modalShow: false,
      inputValue: '',
      list: this.radioList
    };
  },
  methods: {
    ok: function ok() {
      var _this = this;
      this.$emit('input', this.radioValue);
      // 值被设置后,需要设置出来label的值
      var radioItem = this.radioList.find(function (v) {
        return v.id === _this.radioValue;
      });
      if (radioItem) {
        this.label = radioItem[this.labelField];
      } else {
        this.label = null;
        this.radioValue = null;
      }
      this.$emit('on-ok', {
        label: this.label,
        value: this.radioValue
      });
    },
    selectClick: function selectClick() {
      this.radioValue = this.value;
      this.inputValue = '';
      this.inputValueChange();
      this.modalShow = true;
    },
    inputValueChange: function inputValueChange() {
      var _this2 = this;
      this.list = this.radioList.filter(function (v) {
        return v[_this2.labelField].includes(_this2.inputValue);
      });
    },
    deleteRadio: function deleteRadio() {
      this.radioValue = null;
      this.label = '';
      this.$emit('input', this.radioValue);
      this.$emit('on-ok', {
        label: null,
        value: null
      });
    }
  }
};