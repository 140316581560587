// import { getAuth } from "@/libs/auth";
export default {
  path: "/work-flow/setting",
  name: "work-flow-setting",
  meta: {
    auth: true,
    title: "编辑工作流"
  },
  component: function component() {
    return import("@/pages/dsp/system-config/work-flow/work-flow-setting");
  }
  // beforeEnter: async (to, from, next) => {
  //     let isPermission = await getAuth("work-flow-setting");
  //     if (isPermission) {
  //         next();
  //     } else {
  //         next({ name: "403" });
  //     }
  // },
};